:root { 
    --contact-form-card-transition: 0.2s ease-in
}


.Contact-Section{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    position: relative;

    gap: 2em;
    min-height: 90vh;


    width: 90%;

    background-color: transparent;

    margin-bottom: 30px;


}



.Contact-Form{
    background-color: var(--clr-contact-form-background);
    display: flex;
    flex-direction: column;

    gap: 1em;

    width: 600px;

    padding: 20px;

    color: white;

    border-radius: 8px;

    transition: var(--contact-form-card-transition);
}

.ContactSection-1{
    background-color: transparent;
}

.Contact-Title{
    margin-top: auto;
    margin-bottom: auto;
    text-align: left;

    font-size: 18px;

}



.Contact-Inputs:focus{
    border-bottom: 1px solid var(--clr-1);
    outline: none;
    transition: 0.2s ease-in-out;
}

.ContactSection-2{
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 0em;
}

.mail-section{
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    gap: 1em;
    background-color: transparent;
}

.Contact-Inputs{
    width: 90%;
    padding: 5px;
    font-size: 15px;

    background-color: transparent;
    border: 0px solid transparent;
    border-bottom: 1px solid white;

    color: white;

    
}

.Contact-textarea{
    resize: none;
}

.Contact-Submit{
    width: 40%;
    border: 1px solid white;

    background-color: transparent;
    padding: 5px;

    font-size: 15px;

    color: white;

    border-radius: 5px;

}

.Contact-Submit:hover{
    transition: 0.2s ease-in-out;
    color: var(--clr-1);
    border: 1px solid var(--clr-1);
}

/* contact discord presence  */

@keyframes pulse-animation {
    0% {
        box-shadow: 0 0 0 0px rgba(201, 201, 201, 0.9);
    }

    100% {
        box-shadow: 0 0 0 6px rgba(255, 0, 0, 0);

    }
}



.circle {
    position: absolute;
    bottom: 0;
    right: 0;

    display: flex;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    box-shadow: 0px 0px 1px 1px #0000001a;
}

.circle:hover{
    cursor: pointer;
}



.discord-pulse-online{
    background-color: rgb(44, 190, 44);
}

.discord-pulse-idle{
    background-color: rgb(225, 188, 69);
}

.discord-pulse-dnd{
    background-color: var(--clr-4);
}

.discord-pulse-offline{
    background-color: rgb(149, 149, 149)
}

.pulse {
    animation: pulse-animation 1.5s infinite;
}




.Discord-section{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    background-color: var(--clr-card-clr);
    border-radius: 10px;
    width: 180px;
    padding: 10px;
}

.discord-pfp{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
}

.Astra-Discord-Img{
    width: 50px;
    border-radius: 100%;
}

.discord-tag{
    width: 100%;
    background-color: transparent;
}

.discord-tag-user{
    margin-top: auto;
    margin-bottom: auto;

    text-align: center;

    font-size: 15px;
}

.discord-tag-user span{
    color: var(--clr-1);
}

/* contact ICONs */ 

.Contact-Icons-Section{
    display: flex;
    flex-direction: row;
    gap: 3em;
    align-items: center;
    justify-content: center;

    width: 100%;
    position: absolute;
    bottom: 0;
    background-color: transparent;
}

.contact-icon-tag{
    text-decoration: none;
    color: white;
    transition: all .2s;
}

.contact-icon-tag:hover{
    color: var(--clr-1);
    transform: translateY(-5px);
}



.Contact-Icon{
    font-size: 25px;
}




@media screen and (min-width: 1600px) {
    .Contact-Section{
        background-color: transparent;
    }

    .Contact-Form{
        width: 800px;
        transition: var(--contact-form-card-transition);
    }

    .Contact-Submit{
        width: 250px;
    }
}



@media screen and (max-width: 1050px) {
    .Contact-Section{
        width: 100%;
        background-color: transparent;
    }
    .Contact-Form{
        width: 70%;
        transition: var(--contact-form-card-transition);
        
    }

}

@media screen and (max-width: 800px) {
    .Contact-Section{
        background-color: transparent;
        width: 100%;
    }

    .Contact-Form{
        width: 80%;
        transition: var(--contact-form-card-transition);
    }
}

@media screen and (max-width: 500px) {
    .Contact-Section{
        background-color: transparent;
    }

    .Contact-Form{
        width: 95%;
        transition: var(--contact-form-card-transition);
    }

    .Contact-Inputs{
        width: 100%;
    }


    .Contact-Submit{
        width: 50%;
    }

    .Contact-Icons-Section{
        gap: 2em;
    }
}


