.Tech-Card{
    background-color:var(--clr-card-clr);

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1em;

    padding: 20px;

    border-radius: 10px;

    transition: all 0.2s ease-in-out;

}




.Tech-Img{
    width: 40px;
}

.Tech-Des{
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    background-color: transparent;

    font-size: 16px;
}

.Tech-Card:hover{
    transform: translateY(-7px);
}