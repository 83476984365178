.Music {
    min-height: 100vh;
    width: 95%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background-color: pink; */

    gap: 4em;

    margin-top: 60px;
    margin-bottom: 40px;

}

.Music-title {
    color: var(--clr-1);
    text-align: center;
    font-size: 21px;
}

.my-instruments-section {
    width: 80%;
}

.instruments-div {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1em;
    /* background-color: blue; */



    height: 100%;

    object-fit: cover;


}

.experience-charts-holder {
    /* background-color: orange; */
    width: 90%;

    display: grid;
    grid-template-columns: 0.6fr 1fr;
    gap: 1em;
}

.music-experience-chart {
    background-color: var(--clr-card-clr);
    width: 100%;


    padding: 20px 30px;

    border-radius: 10px;

    /* background-color: pink; */

}



.height-of-music-bar-graph {
    display: flex;
    flex-direction: column;
    gap: 1em;
}

/* .height-of-music-bar-graph h3{
    color: var(--clr-1);
} */

.where-i-played {

    display: flex;
    flex-direction: column;
    gap: 0.5em;
    background-color: var(--clr-card-clr);
    width: 100%;

    padding: 20px;
    border-radius: 10px;
}

.where-i-played h3 {
    text-align: center;
}

.music-played-places-grid {
    display: flex;
    /* Align child items horizontally */
    gap: 1em;
    /* Space between items */
    padding: 10px;
    /* Optional padding */
}



.places-Played-card-holder {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1em;
    background-color: var(--clr-card-inside-card);
    /* background-color: blue; */
    width: 100%;


    padding: 20px;
    border-radius: 5px;
}

.places-played-image {
    max-width: 100%;
    max-height: 60px;
    /* Set a consistent max height */
    height: auto;
    object-fit: contain;
    /* Ensures the image scales while maintaining aspect ratio */
    border-radius: 5px;
    /* Optional: To add a border radius to the images */
}

.places-played-title {
    font-size: 13px;
}

.places-played-card-description ul {
    font-size: 11px;
}

.music-year-counter-holder {
    /* background-color: pink; */
    text-align: center;
    font-size: 12px;
}



.recorded-instruments {
    display: flex;
    flex-direction: column;
    gap: 1em;

    /* background-color: blue; */

    width: 95%;
}

.created-music-cards-holder {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* background-color: pink; */
    /* place-items: center; */
    gap: 2em;

    width: 100%;
}

.music-card {
    display: flex;
    flex-direction: column;
    background-color: var(--clr-card-clr);

    border-radius: 10px;

}

.music-vid-model {
    width: 100%;
    /* height: 600px; */

    border-radius: 10px 10px 0 0;

}

.about-music-card-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px;

    text-align: center;

    /* background: pink; */
}


.Instrument-played {
    background-color: rgba(0, 0, 0, 0.226);
    border-radius: 8px;
    padding: 10px;
}





/* external instrument card  */
.InstrumentCard {
    background-color: var(--clr-card-clr);

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1em;

    padding: 20px;

    border-radius: 5px;

    transition: all 0.2s ease-in-out;

}




.Instrument-Img {
    max-width: 100%;
    max-height: 70px;
    /* Set a consistent max height */
    height: auto;
    object-fit: contain;
    /* Ensures the image scales while maintaining aspect ratio */
    border-radius: 5px;
    /* Optional: To add a border radius to the images */
}

.Instrument-Des {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    background-color: transparent;

    font-size: 16px;
}

.InstrumentCard:hover {
    transform: translateY(-7px);
}








/* Timeline container */
.timeline {
    position: relative;
    padding: 20px;
    margin-left: 40px;  /* Indentation for the timeline items */
}

/* Timeline item */
.timeline-item {
    position: relative;
    margin-bottom: 30px;
    cursor: pointer;
}

.timeline-item:hover{
    transform: translateY(-5px);
}

/* Timeline content styling */
.timeline-content {
    background-color: var(--clr-card-clr);
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 300px;  /* Adjust the width of the content box */
    margin-left: 50px;  /* Indentation for the timeline content */
}

/* Timeline dot */
.timeline-item::before {
    content: '';
    position: absolute;
    top: 10px;
    left: -18px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: var(--clr-2);
    border: 3px solid #fff;
}

/* Optional line connecting items */
.timeline::after {
    content: '';
    position: absolute;
    top: 0;
    left: 5px;
    bottom: 0;
    width: 2px;
    background-color: var(--clr-1);
}

/* Timeline details styling */
.timeline-details {
    margin-top: 10px;
}

/* Title styling */
.timeline-title {
    display: block;
    font-weight: 600;
    font-size: 1.1em;
    margin-bottom: 5px;
    color: var(--clr-2);  /* Customize color */
}

/* Description styling */
.timeline-description {
    font-size: 0.9em;
    color: rgb(177, 177, 177);
    line-height: 1.5;
}


@media screen and (max-width: 1050px) {
    .instruments-div {
        grid-template-columns: repeat(2, 1fr);

    }

    .music-experience-chart {
        width: 70%;
    }

    .recorded-instruments {
        width: 90%;
        /* background-color: blue; */
    }

    .created-music-cards-holder {
        grid-template-columns: repeat(2, 1fr);
    }

    .experience-charts-holder {
        grid-template-columns: 1fr 1fr;
        gap: 1m;
    }

    .music-experience-chart {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        text-align: center;
    }
}


@media screen and (max-width: 900px) {
    .experience-charts-holder {
        display: flex;
        flex-direction: column;
    }
}


@media screen and (max-width: 700px) {

    .recorded-instruments {
        width: 100%;
        /* background-color: blue; */
    }

    .created-music-cards-holder {
        display: flex;
        flex-direction: column;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .music-card {
        /* background-color: pink; */
        width: 95%;

        display: flex;
        flex-direction: column;
        /* align-items: center; */
        justify-content: center;
    }

    .music-vid-model {
        width: 100%;

        height: 400px;
    }


}

@media screen and (max-width: 600px) {

    .music-played-places-grid {
        display: flex;
        flex-direction: column;
    }
}


@media screen and (max-width: 500px) {
    .my-instruments-section {
        /* background-color: pink; */

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        gap: 1em;

    }

    .music-experience-chart {
        width: 100%;
    }

    .InstrumentCard {
        width: 100%;
    }


    .instruments-div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        /* background-color: blue; */

        width: 70%;

    }




}



@media screen and (max-width: 300px) {
    .music-card {
        width: 100%;
    }

}






@media screen and (min-width: 1500px) {
    .created-music-cards-holder {
        grid-template-columns: repeat(4, 1fr);
    }
}