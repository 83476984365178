@import url('https://fonts.googleapis.com/css2?family=Nerko+One&family=Poppins:wght@300&family=Roboto&display=swap');

:root {
    --clr-evolution-card-hover: #15181d;
    /* --clr-evolution-card-hover: #2e2e2ece; */
    --clr-card-clr: #15181d;
    --clr-contact-form-background : #12151b;
    --clr-card-inside-card: rgba(0, 0, 0, 0.094);


    /* span colors */
    --clr-1: #2eca9be0;

    --clr-2: #ebb866;
    --clr-3: #ff752d;
    --clr-4: #F16085;
    ---clr-5: #00b8cb;
    --clr-6: #969c95;
    --clr-52: #c3afff;
    /* span colors END */


    --clr-green: #51ff0015;
    --clr-green:#00ff9912;
    /* --clr-yellow: #f6e27f54; */
    --clr-yellow: #ffd50018;
    --clr-pink: #f1608417;
    --clr-blue: #00f7ff3f;


    /* fonts */ 
    --clr-green-font: #57ffbcde;
    --clr-yellow-font: #ffe359e8;
    --clr-blue-font: #aafff9;

    --clr-pink-font: #ff4c79;

    --clr-discord-color: #5865f2;

    --clr-home-container: #0e1117;
}

body, html{
    background-color: #0e1117;
}


.Home-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: white;
    background-color: var(--clr-home-container);
    /* background-color: #0d1217; */
}


::selection {
    background: var(--clr-1);
}


.margin-fix{
    margin-top: auto;
    margin-bottom: auto;
}

