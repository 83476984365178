:root {
    --clr-evolution-card-color: #26282c;


    /* span colors */
    --clr-1: #2eca9be0;
    --clr-2: #e2a33d;
    --clr-3: #ff752d;
    --clr-4: #F16085;
    --clr-5: #00b8cb;
    --clr-7: #8965eb;

    /* span colors END */
}



.Welcome-Container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 0.5em;
    min-height: 100vh;
}


.abt-txt {
    font-size: 35px;
}

.Weather-Status {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: transparent;
    width: 100%;
}

.weather-image-holder {
    background-color: transparent;
}

.weather-description {
    font-size: 18px;
    background-color: transparent;
}

.weather-Icon {
    background-color: transparent;
    width: 80px;
}

.weather-span {
    color: var(--clr-1);
}

.weather-span-2 {
    color: var(--clr-1);
}








.About-Container {
    min-height: 30vh;
    width: 90%;
    /* background-color: blue; */

    display: grid;
    grid-template-columns: 1fr 1fr;
}




.About-Description-Holder {
    display: flex;
    justify-content: center;
    background-color: transparent;
}






.About-Txt {
    display: flex;
    flex-direction: column;
    justify-content: center;

    padding: 10px;
    background-color: transparent;

}


.Nethan-Name {
    margin-top: auto;
    margin-bottom: auto;

}

.my-brief-info{
    display: flex;
    text-align: center;
    flex-direction: column;
    gap:0.5em;

    font-size: 18px;
}

.college-info{
    background-color: var(--clr-yellow);
    color: var(--clr-2);
    border-radius: 5px;
    padding: 1px 15px;
}


.Description-Holder {
    display: flex;
    flex-direction: column;
    padding: 20px;


    /* background-color: blue; */

    border-left: 3px solid white;

    position: relative;

}

.List {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1em;
    list-style: none;

    background-color: transparent;
}

/* discord */
.discord-online {
    color: var(--clr-1);
}

.discord-idle {
    color: var(--clr-2);
}

.discord-dnd {
    color: var(--clr-4);
}

.discord-offline {
    color: var(--clr-6)
}




/* discord ENDS */




.Section {
    font-size: 25px;
}





.Txt-Description {
    font-size: 18px;
}

.Section-description-card{
    padding: 15px;
    border-radius: 8px;

    background-color: var(--clr-card-clr);
}

.main-color {
    color: var(--clr-1);
}

.lang-1 {
    color: var(--clr-7);
}

.lang-2 {
    color: var(--clr-2);
}

.lang-3 {
    color: var(--clr-4);
}

.About-Image {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: transparent;

}

.Nethan-Pic {
    width: 500px;
    border-radius: 8px;
}





/* second */

.About-Container-2 {
    position: relative;
    /* min-height: 30vh; */
    width: 90%;
    /* background-color: pink; */

    display: grid;
    grid-template-columns: 1fr 1fr;
    place-items: center;
}

.About-Image-2 {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 90%;
    height: 500px;

    background-color: transparent;
}

.Developer {
    width: 400px;
    border-radius: 5px;
}

.About-Description-Holder-2 {
    display: flex;
    justify-content: center;

    width: 100%;


    /* background-color: pink; */
}



.About-Txt-2 {
    display: flex;

    flex-direction: column;
    justify-content: center;
    gap: 1em;

    /* padding: 10px; */
    /* background-color: blue; */

    width: 100%;
}

.Developer-Title {
    font-size: 25px;
}


.Description-Holder-2 {
    position: relative;
    display: flex;
    flex-direction: column;

    padding: 15px;


    /* background-color: blue; */

    border-left: 3px solid white;

}





.Section-2 {
    font-size: 30px;
}





@media screen and (min-width: 1500px) {

    .Nethan-Pic {
        width: 500px;
    }

    .Developer {
        width: 420px;
    }

    .About-Container{
        width: 1500px;
    }

    .About-Container-2{
        width: 1500px;
    }

}




/* section 3 */
@media screen and (max-width: 1050px) {
    .About-Container {
        display: flex;
        flex-direction: column;
        background-color: transparent;
    }

    .About-Image {
        padding: 20px;
        background-color: transparent;
    }

    .Nethan-Pic {
        width: 60%;
    }

    .Section {
        background-color: transparent;
        text-align: center;
    }


    /* the second section */

    .About-Container-2 {
        display: flex;
        flex-direction: column;
        background-color: transparent;
    }

    .Developer-Title {
        text-align: center;
    }

    .Developer {
        width: 60%;
    }

    .Section-2 {
        text-align: center;
    }
    
}




@media screen and (max-width: 700px) {



    .About-Container {
        background-color: transparent;
        width: 95%;
    }

    .Nethan-Pic {
        width: 70%;
    }


    .About-Container-2 {
        width: 95%;
    }

    .Developer {
        width: 70%;
    }


}

@media screen and (max-width: 600px) {
    .college-info{
        background-color: transparent;
    }


    .Nethan-Pic {
        width: 80%;
    }

    .About-Container {
        width: 95%;
        background-color: transparent;
        text-align: center;
    }


    .List {
        padding: 0;
    }

    .About-Container-2{
        width: 95%;
    }

    .Txt-Description{
        text-align: center;
    }


    .Description-Holder {
        position: relative;
        background-color: transparent;
        border-left: 0px solid transparent;
        padding: 0;
    }


    .Description-Holder-2 {
        background-color: transparent;
        border-left: 0px solid transparent;
        padding: 0;
    }

    .Developer {
        width: 70%;
    }

}


@media screen and (max-width: 400px) {

    .weather-description{
        width: 90%;
        text-align: center;
    }

}